import React from "react";
import footer_Logo from "../assets/images/footer_logo.png";
import icon_Communityoayo from "../assets/images/icon_communityoayo.png";
import icon_Facebook from "../assets/images/icon_facebook.png";
import icon_Linkedin from "../assets/images/icon_linkedin.png";
import icon_Twitter from "../assets/images/icon_twitter.png";
import icon_YouTube from "../assets/images/icon_youtube.png";
import pci_Logo from "../assets/images/pci-logo.png";

function Footer() {
  return (
    <footer className="bg-black text-white py-10 pl-20">
      <div className="container mx-auto grid grid-cols-2 md:grid-cols-5 gap-8">
        <div>
          <h5 className="text-lg font-bold mb-4">Solutions</h5>
          <ul>
            <li className="mb-2">Freelancers</li>
            <li className="mb-2">Business</li>
            <li className="mb-2">Marketplace</li>
          </ul>
        </div>
        <div>
          <h5 className="text-lg font-bold mb-4">Partners</h5>
          <ul>
            <li className="mb-2">Integration partnerships</li>
            <li className="mb-2">Bank partnerships</li>
            <li className="mb-2">Accounting software integration</li>
          </ul>
        </div>
        <div>
          <h5 className="text-lg font-bold mb-4">About</h5>
          <ul>
            <li className="mb-2">About Payoneer</li>
            <li className="mb-2">Pricing</li>
            <li className="mb-2">Careers</li>
            <li className="mb-2">Press Center</li>
            <li className="mb-2">Multi – jurisdictional licenses</li>
            <li className="mb-2">Mobile app</li>
          </ul>
        </div>
        <div>
          <h5 className="text-lg font-bold mb-4">Help</h5>
          <ul>
            <li className="mb-2">Customer Care</li>
            <li className="mb-2">Security Center</li>
            <li className="mb-2">
              Customer assistance, complaints and disclosures policies
            </li>
          </ul>
        </div>
        <div className="w-full sm:w-auto mt-6 sm:mt-0">
          <h6 className="text-white font-semibold mb-2">Follow us</h6>
          <div className="flex space-x-4">
            <a href="#">
              <img src={icon_Twitter} alt="Twitter" className="h-6 w-6" />
            </a>
            <a href="#">
              <img src={icon_Facebook} alt="Facebook" className="h-6 w-6" />
            </a>
            <a href="#">
              <img src={icon_Linkedin} alt="LinkedIn" className="h-6 w-6" />
            </a>
            <a href="#">
              <img src={icon_YouTube} alt="YouTube" className="h-6 w-6" />
            </a>
            <a href="#">
              <img
                src={icon_Communityoayo}
                alt="Instagram"
                className="h-6 w-6"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="container mx-auto flex justify-between text-gray-400 text-sm mt-8 border-t border-gray-800 pt-4 w-100%">
        <div className=" md:space-x-0 w-50% flex align-middle">
          <img src={footer_Logo} alt="1" className="h-6 mr-2" />

          <p className="text-xs place-items-start ml-0 ">
            © 2005-2024 Payoneer Inc., All Rights Reserved
          </p>
        </div>
        <div className=" md:space-x-0 w-50% flex pr-20">
          <img src={pci_Logo} alt="2" className="h-6 mr-2" />
          <ul className="flex space-x-4 place-items-end">
            <li>
              <a href="#" className="hover:underline">
                Legal
              </a>
            </li>
            <li>
              <a href="#" className="hover:underline">
                Privacy Policy
              </a>
            </li>
            <li>
              <a href="#" className="hover:underline">
                Cookies Policy
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
