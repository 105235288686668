import React, { useState } from "react";
import Step_1 from "../assets/images/Step_1.png";
import Step_2 from "../assets/images/Step_2.png";
import Step_3 from "../assets/images/Step_3.png";
import Step_4 from "../assets/images/Step_4.png";
import Step_5 from "../assets/images/Step_5.png";

function ResourceHub() {
  const [selectedItem, setSelectedItem] = useState("How to Add Funds");
  const [showCardDetails, setShowCardDetails] = useState(false);

  const handleMenuClick = (item) => {
    setSelectedItem(item);
    if (item !== "Create Linked Cards") {
      setShowCardDetails(false);
    }
  };

  const handleCardDetailClick = () => {
    setShowCardDetails(!showCardDetails);
  };

  return (
    <div className="flex bg-[#1A1A1A] ">
      {/* Sidebar */}
      <div className="bg-[#1A1A1A] text-white w-[40%] p-6 border-r-2 border-white">
        <div className="mb-4 w-[40%]">
          <h1 className="text-2xl font-semibold text-white mb-5 pl-1">
            Table of Contents
          </h1>
          <input
            type="text"
            placeholder="Search"
            className="w-full p-2 rounded-full bg-white border-b-1 border-white"
          />
        </div>
        <ul>
          <li
            className={`mb-2 py-2 cursor-pointer hover:text-orange-500 ${
              selectedItem === "How to Add Funds"
                ? "bg-gray-700 border-b-4 border-[#f97316]"
                : ""
            }`}
            onClick={() => handleMenuClick("How to Add Funds")}
          >
            How to Add Funds?
          </li>
          <li
            className={`mb-2 py-2 cursor-pointer hover:text-orange-500 ${
              selectedItem === "Create Linked Cards"
                ? "bg-gray-700 border-b-4 border-[#f97316]"
                : ""
            }`}
            onClick={() => handleMenuClick("Create Linked Cards")}
          >
            Create Linked Cards, Cashbacks, & set Card Limits
          </li>
          <li
            className={`mb-2 py-2 cursor-pointer hover:text-orange-500 ${
              selectedItem === "Request New Receiving Accounts"
                ? "bg-gray-700 border-b-4 border-[#f97316]"
                : ""
            }`}
            onClick={() => handleMenuClick("Request New Receiving Accounts")}
          >
            Request New Receiving Accounts
          </li>
          <li
            className={`mb-2 py-2 cursor-pointer hover:text-orange-500 ${
              selectedItem === "Specific Report"
                ? "bg-gray-700 border-b-4 border-[#f97316]"
                : ""
            }`}
            onClick={() => handleMenuClick("Specific Report")}
          >
            How do I create a specific report or statement?
          </li>
          <li
            className={`mb-2 py-2 cursor-pointer hover:text-orange-500 ${
              selectedItem === "Request a Payment"
                ? "bg-gray-700 border-b-4 border-[#f97316]"
                : ""
            }`}
            onClick={() => handleMenuClick("Request a Payment")}
          >
            Request a Payment
          </li>
          <li
            className={`mb-2 py-2 cursor-pointer hover:text-orange-500 ${
              selectedItem === "BATCH Payments"
                ? "bg-gray-700 border-b-4 border-[#f97316]"
                : ""
            }`}
            onClick={() => handleMenuClick("BATCH Payments")}
          >
            BATCH Payments:
          </li>
          <li
            className={`mb-2 py-2 cursor-pointer hover:text-orange-500 ${
              selectedItem === "Role Management"
                ? "bg-gray-700 border-b-4 border-[#f97316]"
                : ""
            }`}
            onClick={() => handleMenuClick("Role Management")}
          >
            Role Management
          </li>
          <li
            className={`mb-2 py-2 cursor-pointer hover:text-orange-500 ${
              selectedItem === "Adding a new Transfer Method"
                ? "bg-gray-700 border-b-4 border-[#f97316]"
                : ""
            }`}
            onClick={() => handleMenuClick("Adding a new Transfer Method")}
          >
            Adding a new Transfer Method
          </li>
          <li
            className={`mb-2 py-2 cursor-pointer hover:text-orange-500 ${
              selectedItem === "Payment to a Supplier"
                ? "bg-gray-700 border-b-4 border-[#f97316]"
                : ""
            }`}
            onClick={() => handleMenuClick("Payment to a Supplier")}
          >
            How do I send a Payment to a Supplier?
          </li>
          <li
            className={`mb-2 py-2 cursor-pointer hover:text-orange-500 ${
              selectedItem === "Support Centre"
                ? "bg-gray-700 border-b-4 border-[#f97316]"
                : ""
            }`}
            onClick={() => handleMenuClick("Support Centre")}
          >
            Payoneer Support Centre
          </li>
        </ul>
      </div>

      {/* Main Content */}
      <div className=" text-white w-[60%] p-6">
        {selectedItem === "How to Add Funds" && (
          <>
            <h1 className="text-4xl font-semibold text-orange-400">
              How to Add Funds?
            </h1>
            <div className="bg-orange-500 h-[4px] w-[25%] mt-3"></div>
            <p className="text-sm mt-4 mb-6 pr-[200px]">
              Let’s say you need to send some payments from your Payoneer
              account, but your current balance doesn’t have enough funds to
              cover the payments. Or maybe you’re expecting some money in from a
              client or marketplace in the coming days, but you don’t want to
              wait till then to pay some bills.
            </p>
            <p className="text-sm mt-4 mb-6 pr-[200px] font-bold">
              To get started, follow these steps:
            </p>
            {/* Step 1 */}
            <p className="text-sm mt-4 mb-6 pr-[200px]">
              <strong>Step 1:</strong> Sign in to Payoneer.
            </p>
            <img
              className="custom-image w-[250px] ml-[300px]"
              src={Step_1}
              alt=""
            />
            {/* Step 2 */}
            <p className="text-sm mt-6 mb-6 pr-[200px]">
              <strong>Step 2:</strong> On your account home page, click Add
              funds in any of the balance boxes where it is shown. (If you don’t
              see an Add funds option on any of your balances, it means you are
              not eligible for this feature.)
            </p>
            <img
              className="custom-image w-[250px] ml-[300px]"
              src={Step_2}
              alt=""
            />
            {/* Step 3 */}
            <p className="text-sm mt-6 mb-6 pr-[200px]">
              <strong>Step 3:</strong> Review the on-screen information to learn
              more about adding funds, then click I want to add funds.
            </p>
            <img
              className="custom-image w-[250px] ml-[300px]"
              src={Step_3}
              alt=""
            />
            {/* Step 4 */}
            <p className="text-sm mt-6 mb-6 pr-[200px]">
              <strong>Step 4:</strong> Review some key information about adding
              funds, then click Get started.
            </p>
            <img
              className="custom-image w-[250px] ml-[300px]"
              src={Step_4}
              alt=""
            />
            {/* Step 5 */}
            <p className="text-sm mt-6 mb-6 pr-[200px]">
              <strong>Step 5:</strong> We may ask you to provide some additional
              identity and business details. If so, please provide the requested
              information. If not, you can immediately start adding funds using
              the receiving account(s) that are shown when the Only accounts for
              adding funds toggle is on.
            </p>
            <img
              className="custom-image w-[250px] ml-[300px]"
              src={Step_5}
              alt=""
            />
            {/* Step 6 */}
            <p className="text-sm mt-6 mb-6 pr-[200px]">
              <strong>Step 6:</strong> If you were asked to provide information,
              once we review and approve it, we’ll update you by email, and
              you’ll be able to start adding funds. Review and approval usually
              take up to 3 business days.
            </p>
          </>
        )}
        {/* Create Linked Cards 2 */}

        {selectedItem === "Create Linked Cards" && (
          <>
            <h1 className="text-4xl font-semibold text-orange-400">
              Create Linked Cards, Cashbacks, & set Card Limits
            </h1>
            <div className="bg-orange-500 h-[4px] w-[25%] mt-3"></div>
            <p className="text-lg mt-4 mb-6">
              Here's how to create a supplementary card...
            </p>
            <div className="flex gap-4 mb-4">
              <button
                className="bg-gray-700 text-white px-4 py-2 rounded text-xs"
                onClick={handleCardDetailClick}
              >
                How to create a supplementary card?
              </button>
              <button
                className="bg-gray-700 text-white px-4 py-2 rounded text-xs"
                onClick={handleCardDetailClick}
              >
                How do I set a spending limit on a card?
              </button>
              <button className="bg-gray-700 text-white px-4 py-2 rounded text-xs">
                Does Payoneer offer a card with cashback rewards?
              </button>
            </div>
            {showCardDetails && (
              <div className="text-sm mt-4 mb-6 pr-[200px]">
                <h2 className="text-3xl	 font-semibold">
                  How to create a supplementary card?
                </h2>
                <ol className="list-decimal ml-8 space-y-2">
                  <li>Sign into Payoneer.</li>
                  <br />
                  <li>Go to Banks & cards {">"} Payoneer cards.</li>
                  <br />

                  <li>
                    You’ll see a button or link on all eligible primary cards
                    you hold:{" "}
                    <ul>
                      * If you have no linked cards, you’ll see a button named
                      Add linked cards.
                    </ul>
                    <ul>
                      * you already have linked cards, you’ll see a link named
                      Linked cards.
                    </ul>
                    <ul>* Click on the button or link. </ul>
                  </li>
                  <br />

                  <li>
                    In the Add linked cards box, specify the number of linked
                    cards to add. The maximum number of linked cards you can add
                    is shown below the box. Click the tooltip beside the range
                    to get information on how to add even more linked cards.{" "}
                  </li>
                  <br />

                  <li>
                    Optionally, add a nickname prefix. This is recommended so
                    that your linked cards are recognizable afterward. We’ll
                    automatically add a number to the nickname prefix, starting
                    from 1, for each card issued. You can edit the nickname
                    later. For example, enter “My Shoe Store” to get nicknames
                    My Shoe Store 1, My Shoe Store 2, My Shoe Store 3, etc.
                  </li>
                  <br />

                  <li>Review the guidelines about using linked cards.</li>
                  <br />

                  <li>Click Add linked cards.</li>
                  <br />
                </ol>
              </div>
            )}
          </>
        )}
        {/* Request New Receiving Accounts 3*/}
        {selectedItem === "Request New Receiving Accounts" && (
          <>
            <h1 className="text-4xl font-semibold text-orange-400">
              Request New Receiving Accounts
            </h1>
            <div className="bg-orange-500 h-[4px] w-[25%] mt-3"></div>
            <p className="text-lg mt-4 mb-6">
              Here's how to create a supplementary card...
            </p>
            <div className="flex gap-4 mb-4">
              <button
                className="bg-gray-700 text-white px-4 py-2 rounded text-xs"
                onClick={handleCardDetailClick}
              >
                How do I get access to additional receiving account currencies?{" "}
              </button>

              <button className="bg-gray-700 text-white px-4 py-2 rounded text-xs">
                Does Payoneer offer a card with cashback rewards?{" "}
              </button>
            </div>
            {showCardDetails && (
              <div className="text-sm mt-4 mb-6 pr-[200px]">
                <ol className="list-decimal ml-8 space-y-2">
                  <p>
                    To get started with receiving accounts, you request your
                    first set of accounts for the countries where you do
                    business. You may be asked to provide identity documents,
                    required by regulation in order to issue you the accounts.
                    You may also need to complete the Receiving account
                    questionnaire, which will help us better understand the
                    nature of your business.
                  </p>

                  <p>
                    In some cases, we may also be required to:{" "}
                    <ul>
                      verify your bank account (you can learn more about bank
                      account verification here)
                    </ul>
                    <ul>
                      * collect information about your company’s ultimate
                      beneficial owners UBOs (you can learn more about providing
                      UBO information here)
                    </ul>
                  </p>
                  <br />

                  <p>
                    In such cases, we’ll send you an email, requesting that you
                    provide the information. The request will also appear in the
                    Verification Center. Please provide this information as soon
                    as possible to avoid delays in receiving account approval
                    and/or receiving your payments.{" "}
                  </p>
                  <br />
                  <h2 className="text-3xl	 font-semibold">
                    How do I get access to additional receiving account
                    currencies?
                  </h2>
                  <p className="mt-1">
                    Additional currencies available to you are shown in the top
                    area of the Receiving accounts page. To request a receiving
                    account in an additional currency, select it and follow the
                    instructions.{" "}
                  </p>
                </ol>
              </div>
            )}
          </>
        )}
        {/* How do I create a specific report or statement? 4*/}
        {selectedItem === "Specific Report" && (
          <>
            <h1 className="text-4xl font-semibold text-orange-400">
              How do I Create a Specific Report or Statement?
            </h1>
            <div className="bg-orange-500 h-[4px] w-[25%] mt-3"></div>
            <p className="text-lg mt-4 mb-6">
              To get started, follow these steps:{" "}
            </p>
            <div className="flex gap-4 mb-4"></div>
            <ol className="list-decimal ml-8 space-y-2">
              <li>Sign into your Payoneer online account</li>
              <br />
              <li>Click on the "Account Activity" tab</li>
              <br />

              <br />

              <li>
                Under Reports and Statements, choose the relevant statement you
                wish to download: Available Statements: Account Statement for
                Amazon, Confirmation of receiving account, Monthly statement,
                and Letter of good standing{" "}
              </li>
              <br />

              <li>
                Inside the relevant statement type, fill out the requested
                details{" "}
              </li>
              <br />

              <li>
                Once you have filled out the form with all the requested
                information, click on "Download"
              </li>
              <br />

              <li>Fill in the requested information.</li>
              <br />
              <li>Click Download to generate and download the file.</li>
              <br />
              <p>
                Please note:
                <ul>
                  * When you click Download, a new window will open where you
                  can check the status of the generation and download.
                </ul>
                <ul>
                  * you already have linked cards, you’ll see a link named
                  Linked cards.
                </ul>
                <ul>
                  * The file is saved automatically to your computer’s Downloads
                  folder.
                </ul>
                <ul>
                  * Reports are generated in the background, so you can navigate
                  elsewhere in Payoneer while you wait.
                </ul>
              </p>
            </ol>
          </>
        )}
        {/* Request a Payment 5 */}

        {selectedItem === "Request a Payment" && (
          <>
            <h1 className="text-4xl font-semibold text-orange-400">
              Request a Payment{" "}
            </h1>
            <div className="bg-orange-500 h-[4px] w-[25%] mt-3"></div>
            <br />

            <div className="flex gap-4 mb-4">
              <button
                className="bg-gray-700 text-white px-4 py-2 rounded text-xs"
                onClick={handleCardDetailClick}
              >
                How to create a supplementary card?
              </button>
            </div>
            <p className="text-lg mt-4 mb-6">
              Request a Payment (BLS Service previously) is a service that
              enables Payoneer customers to request payments directly with their
              clients. The customers initiate a request for a payment from their
              Payoneer Account, which the payer then receives to their email and
              to the payer dashboard in the payer entity in their Payoneer
              Account.{" "}
            </p>
            {showCardDetails && (
              <div className="text-sm mt-4 mb-6 pr-[200px]">
                <h2 className="text-3xl	 font-semibold">How to get Started? </h2>
                <br />

                <ol className="list-decimal ml-8 space-y-2">
                  <li>
                    To request a payment, click on "Get Paid" in the main menu
                    in your Payoneer account and choose "Request a payment".
                  </li>{" "}
                  <br />
                  <li>
                    Fill out the payment details and submit the request.{" "}
                  </li>{" "}
                  <br />
                  <li>
                    Once done, we will send an e-mail to your desired payer with
                    instructions on how to make a payment.{" "}
                  </li>
                  <br />
                  <br />
                  <p>
                    Once the payment request is submitted, we will send an
                    e-mail to your desired payer with instructions on how to
                    make a payment.
                    <br />
                    <br />
                    If you have not used request a payment before, you will be
                    asked to provide information about your business and supply
                    documents to confirm your identity. These steps are
                    necessary to verify that your business is supported under
                    our terms of service and to prevent fraud and identity
                    theft.
                    <br />
                    <br />
                    Once you submit the requested documents, you’ll be able to
                    start requesting payments immediately. We’ll be in touch by
                    email if we need additional details.
                    <br />
                    <br />
                    Please note that customers who live in some countries must
                    first receive a minimum of 5,000 USD in total payments. If
                    you are from one of these countries, you can see how close
                    you are to the minimum threshold required on the request
                    payment page.
                  </p>
                </ol>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default ResourceHub;
