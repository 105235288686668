import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Figure from "../assets/images/Figure.png";
import Bottomdate from "../assets/images/Bottomdate.png";

function MultipleItems() {
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false, // We will manually control arrows
  };

  return (
    <div className="w-100% bg-[#1A1A1A] pt-10 pb-10">
      <p className="pb-10 relative text-3xl text-center text-white after:content-[''] after:block after:w-16 after:h-1 after:bg-orange-500 after:mx-auto after:mt-2">
        Featured resources
      </p>
      <div className="slider-container bg-[#1A1A1A] w-[1200px] m-auto">
        <Slider ref={sliderRef} {...settings}>
          {/* 1 */}
          <div className="container mx-auto flex ">
            <div className="grid grid-cols-1 md:grid-cols-1 ">
              <div className="bg-[#4B4B51] p-8 rounded-lg shadow-lg ">
                <img className="w-[350px]" src={Figure} alt="" />
                <h4 className="text-1xl font-thin	 mb-4 text-white">
                  Developing a global business strategy: Benefits and steps
                </h4>
                <p className="text-white font-thin text-xs">
                  Developing a successful global business strategy is crucial
                  for any business looking to…
                </p>
                <img className="w-[250px] pt-5" src={Bottomdate} alt="" />
              </div>
            </div>
          </div>
          {/* 1 */}
          <div className="container mx-auto flex ">
            <div className="grid grid-cols-1 md:grid-cols-1 ">
              <div className="bg-[#4B4B51] p-8 rounded-lg shadow-lg ">
                <img className="w-[350px]" src={Figure} alt="" />
                <h4 className="text-1xl font-thin	 mb-4 text-white">
                  Developing a global business strategy: Benefits and steps
                </h4>
                <p className="text-white font-thin text-xs">
                  Developing a successful global business strategy is crucial
                  for any business looking to…
                </p>
                <img className="w-[250px] pt-5" src={Bottomdate} alt="" />
              </div>
            </div>
          </div>

          {/* 1 */}
          <div className="container mx-auto flex ">
            <div className="grid grid-cols-1 md:grid-cols-1 ">
              <div className="bg-[#4B4B51] p-8 rounded-lg shadow-lg ">
                <img className="w-[350px]" src={Figure} alt="" />
                <h4 className="text-1xl font-thin	 mb-4 text-white">
                  Developing a global business strategy: Benefits and steps
                </h4>
                <p className="text-white font-thin text-xs">
                  Developing a successful global business strategy is crucial
                  for any business looking to…
                </p>
                <img className="w-[250px] pt-5" src={Bottomdate} alt="" />
              </div>
            </div>
          </div>

          {/* 1 */}
          <div className="container mx-auto flex ">
            <div className="grid grid-cols-1 md:grid-cols-1 ">
              <div className="bg-[#4B4B51] p-8 rounded-lg shadow-lg ">
                <img className="w-[350px]" src={Figure} alt="" />
                <h4 className="text-1xl font-thin	 mb-4 text-white">
                  Developing a global business strategy: Benefits and steps
                </h4>
                <p className="text-white font-thin text-xs">
                  Developing a successful global business strategy is crucial
                  for any business looking to…
                </p>
                <img className="w-[250px] pt-5" src={Bottomdate} alt="" />
              </div>
            </div>
          </div>
        </Slider>
        {/* button */}
        <div className="flex items-center justify-center mt-4">
          {/* Left Arrow Button */}
          <button
            className="bg-gray-500 text-white p-2 rounded-full hover:bg-gray-600"
            onClick={() => sliderRef.current.slickPrev()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="3"
              stroke="currentColor"
              className="w-3 h-3"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>
          {/* Right Arrow Button */}
          <button
            className="bg-gray-500 text-white p-2 rounded-full hover:bg-gray-600 ml-2"
            onClick={() => sliderRef.current.slickNext()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="3"
              stroke="currentColor"
              className="w-3 h-3"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>
          <div className="custom-divider w-full h-0.5 bg-gray-300 mx-4"></div>
        </div>
      </div>
    </div>
  );
}

export default MultipleItems;
