import React from "react";
import nav_Logo from "../assets/images/nav_Logo.png";

const Header = () => {
  return (
    <header className="bg-[#000000]  border-b-4 border-[#f97316]">
      <div
        className="container mx-auto flex justify-between items-center  w-100%

"
      >
        <img
          src={nav_Logo}
          alt="1"
          className="h-20 w-50 ml-[150px] 

"
        />

        <nav>
          <ul className="flex space-x-4">
            <li>
              <a href="#" className="hover:text-blue-300"></a>
            </li>
            <li>
              <a href="#" className="hover:text-blue-300"></a>
            </li>
            <li>
              <a href="#" className="hover:text-blue-300"></a>
            </li>
            <li>
              <a href="#" className="hover:text-blue-300"></a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
