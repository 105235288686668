import React from "react";
import CoverImg from "../assets/images/CoverImg.png"; // Adjust the path if necessary

const Cover = () => {
  return (
    <section
      className="bg-cover bg-center text-white flex items-center justify-center w-100% h-[800px] bg-[#000000]"
      style={{ backgroundImage: `url(${CoverImg})` }} // Use template literal to insert the image path
    >
      <div className="text-left">
        {/* <h2 className="text-left text-8xl font-bold mb-4 w-[1100px]">
          Welcome to <br />
          <span>Payoneer Edge</span>
        </h2>
        <p className="text-2xl mb-8 w-[600px]">
          Your exclusive platform for everything you need from detailed guides
          on ordering new cards to tips on maximizing Payoneer products and
          staying updated on our latest services.{" "}
        </p> */}
      </div>
    </section>
  );
};

export default Cover;
