import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import slide2 from "../assets/images/slider2.png";

function CustomSlider() {
  // Create a ref for the slider
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  return (
    <div
      class="slik"
      className="custom-slider text-center bg-dark pt-10 bg-[#2D2D2D] overflow-hidden"
    >
      <p className="pb-10 custom-title relative text-3xl text-center text-white after:content-[''] after:block after:w-16 after:h-1 after:bg-orange-500 after:mx-auto after:mt-2">
        Events
      </p>
      <div class="slid" className="custom-slider-container bg-dark m-auto">
        <Slider ref={sliderRef} {...settings}>
          {/* Slide 1 */}
          <div className="custom-slide mx-auto flex ">
            <div className="custom-slide-content   rounded-lg shadow-lg w-[500px]">
              <img className="custom-image w-full" src={slide2} alt="" />
            </div>
          </div>

          {/* Slide 2 */}
          <div className="custom-slide mx-auto flex ">
            <div className="custom-slide-content   rounded-lg shadow-lg w-[500px]">
              <img className="custom-image w-full" src={slide2} alt="" />
            </div>
          </div>

          {/* Slide 3 */}
          <div className="custom-slide mx-auto flex ">
            <div className="custom-slide-content  rounded-lg shadow-lg w-[500px]">
              <img className="custom-image w-full" src={slide2} alt="" />
            </div>
          </div>
        </Slider>

        {/* Navigation Buttons */}
        <div className="custom-navigation flex items-center justify-center px-[151px] pb-5">
          {/* Left Arrow Button */}
          <button
            className="custom-prev bg-gray-500 text-white p-2 rounded-full hover:bg-gray-600"
            onClick={() => sliderRef.current.slickPrev()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="3"
              stroke="currentColor"
              className="w-3 h-3"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>

          {/* Right Arrow Button */}
          <button
            className="custom-next bg-gray-500 text-white p-2 rounded-full hover:bg-gray-600 ml-2"
            onClick={() => sliderRef.current.slickNext()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="3"
              stroke="currentColor"
              className="w-3 h-3"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>

          {/* Divider Line */}
          <div className="custom-divider w-full h-0.5 bg-gray-300 mx-4"></div>
        </div>
      </div>
    </div>
  );
}

export default CustomSlider;
