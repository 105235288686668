import Header from "./components/Header";
import Hero from "./components/Hero";
import Table from "./components/Table";
import Services from "./components/Services";
import Contact from "./components/Events";
import Cover from "./components/Cover";
import Footer from "./components/footer";

function App() {
  return (
    <div>
      <Header />
      <Hero />
      <Table />
      <Services />
      <Contact />
      <Cover />
      <Footer />
    </div>
  );
}

export default App;
